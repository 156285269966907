import * as React from 'react'
import { Helmet } from 'react-helmet'

import Seo from '../components/SEO'
import till from '../images/people/till.webp'

const Book = () => {
  return (
    <>
      <Seo
        title="Book for call"
        description=' The first call is 15 minutes long and focuses on your situation and your goals. It"s free and there"s no sales pitch.'
      />
      <main className="w-full">
        <div className="flex flex-col items-center h-auto">
          {/* hero section */}
          <div className="w-full flex px-4 pt-8 md:pt-16 pb-8 md:px-24 md:pb-8 flex-col md:flex-row items-center justify-center relative">
            <div className="flex flex-col ">
              <h1 className="typo-h2 text-3xl sm:text-4xl xl:text-5xl  font-semibold">
                Book a call with
                <span className="underline-highlight"> Pairing</span>
              </h1>
              <p className="typo-small-2 max-w-xl xl:text-lg mt-3 p-0">
                The first call is 15 minutes long and focuses on your situation
                and your goals. It's free and there's no sales pitch.
              </p>
            </div>
            <div className="relative mt-16">
              <div className="bg-gray-200 px-4 py-4 rounded-xl absolute -top-16 -right-4 message-box">
                I'm personally taking your call.
              </div>
              <img src={till} alt="Till" srcSet="" className="w-80" />
            </div>
          </div>

          {/* Calendly */}
          <section className="flex flex-col justify-center m-8 md:m-0 md:mt-0 w-full max-w-5xl">
            <span className="underline-highlight w-28 m-auto mb-7 h-8"></span>
            <h1 className="typo-h2 font-semibold text-center leading-relaxed">
              Book now:
            </h1>
            <div className="w-11/12 md:w-full m-auto flex flex-col items-center justify-between pt-4">
              <Helmet>
                <script
                  type="text/javascript"
                  src="https://asset-tidycal.b-cdn.net//js/embed.js"
                  async
                ></script>
              </Helmet>
              <div
                id="tidycal-embed"
                data-path="tillcarlos/pairing-scrum-standup-gues"
              ></div>

              <div id="tidycal-embed"
                className="calendly-inline-widget w-full h-[64rem] md:h-[50rem] min-w-[320px] min-h-[630px]"
                data-path="tillcarlos/discovery-call"
                style={{ minHeight: '630px', minWidth: '320px' }}
              ></div>
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default Book
